$white: #fafafa;
$black: #212121;

// Override default variables before the import
$body-bg: $white;
$body-color: $black;
$primary: #ff511f;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html, body {
  font-family: Inter, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.btn-primary {
  color: $white !important;
}

.gradient-background {
  background: linear-gradient(90deg, #ff511f, #fc9376);
}

.modal-img {
  min-height: 50vh;
  max-height: 70vh;
  object-fit: cover;
}

.close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 100%;
  background: $black;

  svg {
    fill: $white;
  }
}

.custom-card {
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1rem 0 1rem;
  background: $white;
  color: $black;
  border-radius: 1rem;
}

.uploaded-img {
  min-height: 10rem;
  object-fit: cover;
}

.fullscreen-icon {
  width: 1.5em !important;
  height: 1.5em !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #fafafa !important;
}

.check-icon {
  width: 5em !important;
  height: 5em !important;
  fill: #34b233 !important;
  border-radius: 100%;
  border: .5rem solid #34b233;
  padding: .5em;
}

.error-icon {
  width: 5em !important;
  height: 5em !important;
  fill: #DF2020 !important;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.validation-text {
  margin-top: .5rem;
  margin-bottom: 1.5rem;
  font-size: .875rem;
  color: rgba(33, 33, 33, 0.35);
}

.css-5r72g1-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: $primary !important;

  .css-117w1su-MuiStepIcon-text {
    fill: $white !important;
  }
}

.css-5r72g1-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: $primary !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: rgba(255, 81, 31, 0.87) !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-completed {
  color: rgba(255, 81, 31, 0.87) !important;
}

.custom-container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.main {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media only screen and (min-width: 576px) {
  .custom-container {
    max-width: 50rem;
  }
  .custom-card {
    padding: 2rem 2rem 1rem 2rem;
  }
}

//@media only screen and (min-height: 840px) {
//  .main {
//    padding-top: 0;
//    padding-bottom: 0;
//  }
//}